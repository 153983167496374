import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1>NICHT GEFUNDEN</h1>
    <p>Leider ein Link, der ins Leere führt...</p>
  </Layout>
)

export default NotFoundPage
